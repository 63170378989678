import React from "react";
import { graphql } from "gatsby";
import { SanitySlice, SEO } from "~components";
import { useSanitySlices } from "~hooks";

import * as styles from "./styles.module.scss";

/** ============================================================================
 * @page
 * Static page routes @ /*.
 */
const Page = ({ data: staticData }) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const { sanityPage } = staticData;
  const { pagebuilder } = sanityPage;

  const { slices } = useSanitySlices(pagebuilder?.slices || []);

  // ---------------------------------------------------------------------------
  // render

  return (
    <>
      <SEO pageTitle={sanityPage.title} />

      {slices?.map((slice) => (
        <SanitySlice key={slice._key} data={slice} />
      ))}
    </>
  );
};

export default Page;

export const query = graphql`
  query Page($id: String!) {
    sanityPage(id: { eq: $id }) {
      title
      slug {
        current
      }

      #
      # sync this to slices available both locally and in lam-dbp-slices

      pagebuilder {
        slices {
          ...BorderArticleFragment
          ...ColorFeatureFragment
          ...ColorGridFragment
          ...FeatureImageFragment
          ...FullVideoFragment
          ...HoverCardsFragment
          ...ImageGridFragment
          ...LinkListFragment
          ...SimpleImageFragment
          ...SimpleVideoFragment
          ...TextArticleFragment
          ...TypeTableFragment
        }
      }
    }
  }
`;
